.custom-box-espece-counts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -1rem; /* Adjust margin top as needed */
    background-color: #ffffff; /* Default background color */
    border-radius: 1.5rem; /* Material-UI "xl" border radius */
    color: #ffffff; /* White color */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Default colored shadow */
    /* Gradient background */
    /* background: linear-gradient(45deg, #FF8E53, #FE6B8B); */
  }
  
  .custom-box-espece-counts:hover {
    /* Adjust hover effects if needed */
    transform: scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  .custom-box-espece-count-containers {
    display: flex;
    justify-content: space-between;
    padding-top: 8px; /* Material-UI theme spacing 1 is 8px */
    padding-left: 16px; /* Material-UI theme spacing 2 is 16px */
    padding-right: 16px; /* Material-UI theme spacing 2 is 16px */
  }

  .custom-espece-count-text-containers {
    /* line-height: 1.5;
    background-color: blue;/* Adjust line height as needed */
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
  }
  .custom-espece-count-headings {
    font-size: 1.5rem; /* Adjust font size as needed */
    font-weight: 500; /* Lighter font weight */
    color: #108236;
    text-align: center; /* Text color */
  }

  .custom-grid-espece-count-containers {
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
  }
  
  .custom-grid-espece-count-items {
    margin:  8px; /* Equivalent to theme.spacing(2) */
    padding:  5px; /* Equivalent to theme.spacing(2) */
    /* align-items: center;
    justify-content: center;*/
  }
  .custom-grid-espece-count-typographys {
    text-align: center; /* Center text horizontally */
  }

  .custom-grid-espece-count-cards {
    background-color: #ffffff; /* Default background color */
    border-radius: 15px;
    border-color: darkgray; /* Border radius */
    /* Other styles as needed */
  }