/* CustomDatePicker.css */

.custom-calendar {
  /* Your custom styles for the calendar container */
  font-family: Arial, sans-serif;
  /* Add more styles as needed */
   /*background-color: #fff;*/
   background-color: rgba(255, 255, 255, 1);
}
/* .custom-calendar .react-datepicker__calendar-container {
  background-color: #4CAF50; 
  opacity: 1;
} */


/* Customize specific elements within the calendar */
.custom-calendar .react-datepicker__header {
  /* Custom header styles */
  background-color: #4CAF50;
  /* Add more header styles as needed */
}


/* Customize the day elements */
.custom-calendar .react-datepicker__day {
  /* Custom day styles */
  color: #333;
  /* Add more day styles as needed */
}

/* Customize the selected day */
.custom-calendar .react-datepicker__day--selected {
  /* Custom selected day styles */
  background-color: #007bff;
  color: #fff;
  /* Add more selected day styles as needed */
}
/* .datePicker .full-width {
  width: 100%;
}*/ 
.datePicker,
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}